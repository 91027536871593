const DOMINIO = window.location.protocol + '//' + window.location.host;

$('body').on('contextmenu', function(e){
	return false;
});

$('[data-toggle="buscas"]').click(function(){
	$('.search-area').toggleClass('open');
});

$(document).click(function(e){
	$('.search-area').removeClass('open');

});

$('.campo-busca,[data-toggle="buscas"]').click(function(e){
	e.stopPropagation();
})